<template>
  <div>
    <div v-if="show" id="invoice" style="background-color: #FFFFFF;text-align: initial;padding: 30px 15px;">

      <div class="row mb-5" style="margin-bottom: 50px;">
        <div class="col-3">
          <div class="text-center" style="padding: 2px 7px;">
            <h6 style="margin-bottom: 0.5rem;margin-top: 0.5rem;"><b>{{ company_name }}</b></h6>
            <p style="margin-bottom: 0.5rem;font-size: 14px;" v-if="tax_no"><b>الرقم الضريبي: </b><b>{{tax_no}}</b></p>
            <p style="margin-bottom: 0.5rem;font-size: 14px;" v-if="email"><b>{{email}}</b></p>
            <p style="margin-bottom: 0.5rem;font-size: 14px;direction: ltr;" v-if="mobileFullData(mobile)"><b>{{mobileFullData(mobile)}}</b></p>
            <p style="margin-bottom: 0.5rem;font-size: 14px;" v-if="city_name || address_1"><b v-if="city_name">{{city_name}}</b><b v-if="address_1"> - {{address_1}}</b></p>
          </div>
        </div>
        <div class="col-6 text-center">
          <h1 style="margin-bottom: 0.5rem;"><b>دفعة مالية</b></h1>
          <h1 style="margin-bottom: 0.5rem;" v-if="payment_code"><b>كود الدفعة: {{ payment_code }}</b></h1>

        </div>
        <div class="col-3">
          <h2 style="padding-bottom: 15px;"><img :src="logo_url" style="height: 55px;" alt=""></h2>
          <h6 class="text-center"><b>{{ $t('expenses_incomes_print.date') }}: {{ today_date }}</b></h6>
          <h6 v-if="invoice_code" class="text-center"><b>كود الفاتورة: {{ invoice_code }}</b></h6>
        </div>


      </div>
            <table class="table table-bordered font-size-16px">
              <tr>
                <th>{{ $t('expenses_incomes_print.received_from_mr') }}</th>
                <td>{{ data.customer_fullname }}</td>
              </tr>
              <tr>
                <th>{{ $t('expenses_incomes_print.amount_of') }}</th>
                <td>{{ data.amount }} {{ data.currency_code }}</td>
              </tr>
              <tr v-if="data.payment_method_name">
                <th>{{ $t('expenses_incomes_print.payment_method') }}</th>
                <td>{{ data.payment_method_name }}</td>
              </tr>
              <tr v-if="data.method_type_type == 'Treasuries'">
                <th>{{ $t('expenses_incomes_print.treasury') }}</th>
                <td>{{ data.treasury.name }}</td>
              </tr>
              <tr v-if="data.method_type_type == 'Banks'">
                <th>{{ $t('expenses_incomes_print.bank') }}</th>
                <td>{{ data.bank.name }}</td>
              </tr>
              <tr v-if="data.created_date">
                <th>{{ $t('expenses_incomes_print.created_date') }}</th>
                <td>{{ data.created_date }}</td>
              </tr>
              <tr v-if="data.notes">
                <th>{{ $t('expenses_incomes_print.and_that_for') }}</th>
                <td>{{ data.notes }}</td>
              </tr>
            </table>


      <table class="table table-bordered text-center mt-10">
        <thead>
        <tr>
          <th>{{ $t('expenses_incomes_print.recipient_signature') }}</th>
          <th>{{ $t('expenses_incomes_print.signature_of_the_treasurer') }}</th>
          <th>{{ $t('expenses_incomes_print.signature_of_the_manager') }}</th>
        </tr>
        </thead>
        <tr>
          <td>
            <p>.................</p>
          </td>
          <td>
            <p>.................</p>
          </td>
          <td>
            <p>.................</p>
          </td>
        </tr>
      </table>



    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import {jsPDF} from "jspdf";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "printPayment",
  data() {
    return {
      mainRoute: 'sales/payment_detail',
      data: {},
      company_name: null,
      tax_no: null,
      logo_url: null,
      city_name: null,
      address_1: null,
      email: null,
      mobile: null,
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      today_date: null,
      invoice_code: null,
      payment_code: null,

    }
  },
  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data = response.data.data;
        this.payment_code = response.data.data.code;
        if(response.data.data.company){
                  this.company_name = response.data.data.company.business_name ?? null;
        this.tax_no = response.data.data.company.tax_no ?? null;
        this.logo_url = response.data.data.company.logo_url ?? null;
        this.city_name = response.data.data.company.city_name ?? null;
        this.address_1 = response.data.data.company.address_1 ?? null;
        this.email = response.data.data.company.email ?? null;
        this.mobile = response.data.data.company.mobile ?? null;
        }

        if (response.data.data.invoice)
          this.invoice_code = response.data.data.invoice.invoice_code ?? null;

        this.show = true;
      });
    },
    // async getCompany() {
    //   await ApiService.get("/getUserPersonal").then((response) => {
    //     this.company_name = response.data.data.company_name ?? null;
    //
    //   });
    // },
    printInvoice() {
      // let tableId = 'invoice'
      // let divToPrint = document.getElementById(tableId);
      // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divToPrint.outerHTML);
      // i18nService.getActiveLanguage();
      // setTimeout(() => {
      //   window.print();
      //   window.close();
      // }, 100)
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", 'pt', [595,842 ]);
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.75,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            window.print();
            // window.close();
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
    mobileFullData(mobile) {
      if (mobile) {
        if (mobile.length > 5) {
          return mobile;
        }
      }
      return null;
    },
  },
  mounted() {
    this.today_date = new Date().toISOString().substr(0, 10);
    let promise = this.getData();
    // let promise2 = this.getCompany();
    Promise.all([promise]).then(() => {
      this.printInvoice();
    });
  }
}
</script>

<style >
@page {
  size: 21cm 29.7cm;
  margin: 0;
}
@media print {
  body{
    width: 21cm;
    height: 29.7cm;
    margin: 0;
    float: right;
  }
  #invoice {
    font-family: "Amiri";
    width: 210mm;
  }
  .font-size-16px{
    font-size: 16px;
  }
}
#invoice {
  font-family: "Amiri";
  width: 210mm;
}
.font-size-16px{
  font-size: 16px;
}

</style>